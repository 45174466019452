import { initLightboxes } from './lightbox';
import { sub } from './chimp.js';
import { initPreviewImages } from './exhibition';
// import 'sticksy';

let timeout;

document.addEventListener('DOMContentLoaded', () => {
    const iframeLB = initLightboxes();

    initPreviewImages();

    // fix lightbox not closing
    window.addEventListener('click', (e) => {
        if (e.target.classList.contains('gslider') && document.body.classList.contains('glightbox-open')) {
            iframeLB.close();
        }
    })

    window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
          let imgToRemove = document.querySelector('.bg.is-visible');
          if (imgToRemove) imgToRemove.parentElement.removeChild(imgToRemove);
        } else {
        //   console.log('This page was loaded normally.');
        }
    });

    const coverLinks = document.querySelectorAll('a[data-cover-image]');
    const backToTopButton = document.querySelector('#back-to-top');


    // fixes bg img overlaying on touch devices
    if (!window.matchMedia( "(hover: none)" ).matches) {
        // hover available
        coverLinks.forEach(l => {
            l.addEventListener('mouseenter', () => {
                if (l.dataset.coverImage == '') return;
                let bg = document.createElement('img');
                bg.classList.add('bg');
                document.body.appendChild(bg);

                window.setTimeout(() => {
                    bg.src = l.dataset.coverImage;
                }, 100);

                timeout = window.setTimeout(() => {
                    bg.classList.add('is-visible');
                }, 100);

                l.bg = bg;
            })
            l.addEventListener('mouseleave', () => {
                if (l.dataset.coverImage == '') return;
                window.clearTimeout(timeout);
                l.bg.classList.remove('is-visible');
                let thisbg = l.bg;
                window.setTimeout(() => {
                    thisbg.remove();
                }, 100)
            })
        })
    }

    let nlsub = document.querySelector('#nlsub');
    if (nlsub) {
        nlsub.addEventListener('submit', e => {
            sub(e);
        })
    }

    backToTopButton.addEventListener('click', topFunction);

    window.onscroll = () => {
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
            backToTopButton.style.display = "block";
          } else {
            backToTopButton.style.display = "none";
          }
    }

    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
})
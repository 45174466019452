export const initPreviewImages = () => {
    const previewImagesContainer = document.querySelector('.p-exhibition__preview-images');
    const fullImagesContainer = document.querySelector('.p-exhibition__images');
    if (previewImagesContainer) {
        const previewImages = previewImagesContainer.querySelectorAll('figure');
    
        previewImages.forEach(p => {
            p.addEventListener('click', (e) => {
                let index = p.dataset.index;
                let corresponding = fullImagesContainer.querySelector(`figure[data-index="${index}"]`);
                window.scrollTo({
                    top: corresponding.getBoundingClientRect().top - 13 + window.scrollY,
                    left: 0, 
                    behavior: 'smooth'
                });
            });
        });
    }
}
import IdleJs from 'idle-js';

const birbOptions = {
    initialDirection: 'left',
}

class Birb {

    constructor(selector = '#birb', options = birbOptions) {
        this.div = document.querySelector(selector);
        this.options = options;
        this.newPosition();
        this.div.dataset.direction = this.options.initialDirection;
    };

    newPosition() {
        this.currPos = {
            x: parseInt(this.div.style.left, 10),
            y: parseInt(this.div.style.top, 10),
        }
        this.nextPos = {
            x: (Math.floor(Math.random() * (window.innerWidth - this.div.clientWidth)) + 1),
            y: (Math.floor(Math.random() * (window.innerHeight - this.div.clientHeight)) + 1)
        }

        // if it's negative, birb is heading to the right
        this.direction = (this.currPos.x - this.nextPos.x) >= 0 ? 'left' : 'right'

        this.div.dataset.direction = this.direction;

        this.div.style.top = this.nextPos.y + 'px';
        this.div.style.left = this.nextPos.x + 'px';
    };

    fly() {
        let rand = Math.round(Math.random() * (5000 - 1000)) + 1000;
        this.newPosition();
        this.timeout = window.setTimeout(() => {
            this.fly();
        }, rand)
    }

    call() {
        this.div.style.opacity = 1;
        this.fly();
    };

    shoo() {
        window.clearTimeout(this.timeout);
        this.div.style.opacity = 0;
    };
}

document.addEventListener('DOMContentLoaded', () => {
    const birb = new Birb();
    
    const idle = new IdleJs({
        idle: 10000,
        events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll'],
        onIdle: () => birb.call(),
        onActive: () => birb.shoo(),
    });
    
    idle.start();
})

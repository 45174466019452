import GLightbox from 'glightbox';

const lightboxOptions = {
    default: {
        touchNavigation: true,
        loop: true,
        openEffect: 'fade',
        closeEffect: 'none',
        zoomable: false,
        moreLength: 0,
        dragToleranceY: 20,
    }
}

export function initLightboxes() {
    const lightbox = GLightbox({
        ...lightboxOptions.default,
        selector: '.glightbox[data-skin="exhibition"]',
        skin: 'exhibition',
        zoomable: false,
    });

    let immediateOpen = false;

    if (location.hash?.slice(1)) {
        // opened by hash
        immediateOpen = true;
    }

    function checkHash(calledByPopState = false) {
        if (location.hash && location.hash?.slice(1)) {
            let index = location.hash.slice(1) - 1;
            lightbox.openAt(index);
        } else if (lightbox.getActiveSlideIndex() != undefined) {
            lightbox.close();
        }
    }

    checkHash();

    window.onpopstate = (event) => {
        checkHash(true);
        console.log(event);
        console.log(immediateOpen);
    }

    lightbox.on('slide_changed', ({prev, current}) => {
        let index = current.index + 1;

        // check lb open or not
        history[prev.slide ? "replaceState" : "pushState"]({},
            document.title,
            window.location.pathname + window.location.search + "#" + index
        );
    })

    lightbox.on('close', () => {
        history.replaceState("", document.title, window.location.pathname + window.location.search);
    })
    
    const iframelightbox = GLightbox({
        ...lightboxOptions.default,
        selector: '.glightbox[data-skin="iframe"]',
        skin: 'iframe',
    });

    return iframelightbox;
}
